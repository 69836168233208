<template>
  <div class="w-100 light section section-team">
    <div class="container-fluid align-self-center position-relative py-5">
      <div class="spacer"></div>
      <p class="pre-title fs-3 text-primary">Your expert team</p>
      <h1 class="h1-large">The Team</h1>
      <div class="spacer"></div>
      <p class="spread-title fs-3">Sales &amp; Leasing</p>
      <div class="row mb-5">
        <div
          v-for="(m, index) in salesMembers"
          :key="index"
          class="col-6 col-md-3 person-card mb-3"
          data-aos="fade"
          :data-aos-delay="200"
        >
          <div class="position-relative">
            <img :src="m.image_url" alt="m.name">
            <div class="person-details p-2 position-absolute bottom-0 w-100">
              <p class="pre-title text-primary fw-bold mb-1">{{ m.title }}</p>
              <p class="fw-bolder text-white mb-0">{{ m.name }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="spacer"></div>
      <p class="spread-title fs-3">Marketing</p>
      <div class="row">
        <div
          v-for="(m, index) in marketingMembers"
          :key="index"
          class="col-6 col-md-3 person-card mb-3"
          data-aos="fade"
          :data-aos-delay="200"
        >
          <div class="position-relative">
            <img :src="m.image_url" alt="m.name">
            <div class="person-details p-2 position-absolute bottom-0 w-100">
              <p class="pre-title text-primary fw-bold mb-1">{{ m.title }}</p>
              <p class="fw-bolder text-white mb-0">{{ m.name }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Team',
  data () {
    return {
      salesMembers: [
        {
          name: 'Chad Stafford',
          title: 'President',
          image_url: 'https://www.occmgmt.com/wp-content/uploads/2020/08/Chad-Stafford.jpg'
        },
        {
          name: 'Chris Harmon',
          title: 'General Manager of Operations',
          image_url: 'https://www.occmgmt.com/wp-content/uploads/2020/10/Chris-Harmon.jpg'
        },
        {
          name: 'Charlie King',
          title: 'Sales and Leasing',
          image_url: 'https://www.occmgmt.com/wp-content/uploads/2020/08/Charlie-King.jpg'
        },
        {
          name: 'Nick Flores',
          title: 'Sales and Leasing',
          image_url: 'https://www.occmgmt.com/wp-content/uploads/2021/03/NickHeadshot.jpg'
        }
      ],
      marketingMembers: [
        {
          name: 'Chelsea Burns',
          title: 'Marketing Director',
          image_url: 'https://www.occmgmt.com/wp-content/uploads/2020/10/Chelsea-Burns.jpg'
        },
        {
          name: 'Calvin Nelson',
          title: 'Digital Solutions Manager',
          image_url: 'https://www.occmgmt.com/wp-content/uploads/2020/10/Calvin-Nelson.jpg'
        },
        {
          name: 'Andrew Meiller',
          title: 'Communication Manager',
          image_url: 'https://www.occmgmt.com/wp-content/uploads/2020/11/andrew-headshot.jpg'
        },
        {
          name: 'Nathen George',
          title: 'Business Development Analyst',
          image_url: 'https://www.occmgmt.com/wp-content/uploads/2020/08/Nathan-George.jpg'
        }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>