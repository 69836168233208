<template>
  <div id="app">
    <div class="logo position-fixed top-0 py-3" style="width: 180px;">
      <img alt="Occidental Management, Inc." src="./assets/occidental-logo.png" class="img-fluid">
    </div>
    <button
      type="button"
      @click="toggleNavigation()"
      class="nav-toggle d-md-none"
    >
      <font-awesome-icon :icon="['far', navIcon]"/>
    </button>
    <div :class="`mobile-nav-wrapper ${navOpen ? 'show' : ''}`">
      <ul class="navigation navigation-mobile list-unstyled h-100 p-5 d-flex flex-column text-end position-relative" v-scroll-spy-active v-scroll-spy-link>
        <!-- <div class="stem"></div> -->
        <li v-for="(item) in sections" :key="item.id" class="py-2 mb-3 position-relative">
          <a @click="toggleNavigation()" :href="`#section-${item.id} stretched-link`">
            <span class="nav-item-dot position-absolute top-50 translate-middle"></span>
            <span class="nav-item-label">{{item.navLabel}}</span>
          </a>
        </li>
      </ul>
    </div>
    <div v-if="navOpen" :class="`modal-backdrop fade ${navOpen ? 'show' : ''}`" @click="toggleNavigation()"></div>
    <ul class="navigation navigation-left list-unstyled d-none d-md-block" v-scroll-spy-active v-scroll-spy-link>
      <li v-for="(item) in sections" :key="item.id">
        <a :href="`#section-${item.id}`">
          <span class="nav-item-dot"></span>
          <span class="nav-item-label">{{item.navLabel}}</span>
        </a>
      </li>
    </ul>
    <div class="section-container" v-scroll-spy="{time: 20}">
      <TextHalf
        id="section-1"
        title="Class A Experts"
        preTitle="Premier CRE Experience"
        imageUrl="img_rendering-ballpark.jpg"
      >
        <p>Leasing. That's our number one goal at Occidental Management. The approach may sound simple but when an entire organization is focused on a single end-game, the results are expedient and impactful. Commercial real estate development puts teams against the clock for both tenants' revenue and space needs. Time and money – it's no secret these obstacles are present in almost every big deal. Occidental Management's expertise in Class A commercial real estate and extensive network mitigate these hurdles and drive one, unified deliverable – executed leases.  </p>
      </TextHalf>
      <TextHalf
        id="section-2"
        title="High Impact Leasing"
        imageUrl="img_rendering-patios.jpg"
        textSide="right"
      >
        <p>Like America's favorite pastime, commercial real estate can be a long game. It requires sound strategy and relies on execution. Occidental Management uses a proven process to identify, cultivate and convert leads into long-term tenant partnerships. Our team is dedicated to innovative approaches that shorten the time-to-lease phase, resulting in quickly executed leases.</p>
      </TextHalf>
      <Map
        id="section-3"
        :properties="properties"
      />
      <Timeline
        id="section-4"
        :sections="timelineSections"
      />
      <TextHalf
        id="section-5"
        title="Epansive CRE Network"
        preTitle="Connected Industry Partners"
        imageUrl="ust-1-1360x780-c.jpg"
        textSide="left"
      >
        <p>Occidental Management understands the importance and power of strong relationships. Company leadership can be found integrated into the industry and community on a national scale. Occidental Management's mission is to add value and that pillar applies to every deal touched by an Occidental Management agent. Our past experience with big-name firms, including the ones listed below, make us prepared to collaborate on deals in order to best serve the project.</p>
        <template v-slot:bottom-row>
          <div class="d-flex mt-5 w-100 justify-content-around justify-content-lg-between flex-wrap">
            <div class="p-2">
              <img src="./assets/1200px-Savills_logo.svg.png" alt="Savill Logo" class="vendor-logo">
            </div>
            <div class="p-2">
              <img src="./assets/grayscale-cushwake.png" alt="Cushman & Wakefiled Logo" class="vendor-logo">
            </div>
            <div class="p-2">
              <img src="./assets/grayscale-JLL.png" alt="JLL Logo" class="vendor-logo">
            </div>
            <div class="p-2">
              <img src="./assets/Cresa-logo-landscape.png" alt="Cresa logo" class="vendor-logo">
            </div>
            <div class="p-2">
              <img src="./assets/grayscale-cbre.png" alt="CBRE logo" class="vendor-logo">
            </div>
          </div>
        </template>
      </TextHalf>
      <Team
        id="6"
      />
    </div>
  </div>
</template>

<script>
import TextHalf from './components/TextHalf.vue'
import Map from './components/Map.vue'
import Timeline from './components/Timeline.vue'
import Team from './components/Team.vue'

let nextSectionId = 1

export default {
  name: 'App',
  components: {
    TextHalf,
    Map,
    Timeline,
    Team
  },
  methods: {
    toggleNavigation() {
      document.body.classList.toggle('scrim-on');
      this.navOpen = !this.navOpen;
      if (this.navOpen) {
        this.navIcon = 'times'
      } else {
        this.navIcon = 'bars'
      }
    },
  },
  data () {
    return {
      nextSectionId: 0,
      navOpen: false,
      navIcon: 'bars',
      sections: [
        {
          id: nextSectionId++,
          navLabel: "Class A Experts",
          /* stats: [
            {
              icon: "file-signature",
              label: "TIME TO LEASE",
              value: "3 mo."
            },
            {
              icon: "file-signature",
              label: "Etiam id consequat ex",
              value: "115k"
            }
          ] */
        },
        {
          id: nextSectionId++,
          navLabel: "Heavy Hitters"
        },
        {
          id: nextSectionId++,
          navLabel: "Our Portfolio"
        },
        {
          id: nextSectionId++,
          navLabel: "Sales Proccess"
        },
        {
          id: nextSectionId++,
          navLabel: "Industry Partners"
        },
        {
          id: nextSectionId++,
          navLabel: "The Team"
        }
      ],
      timelineSections: [
        {
          icon: "filter",
          preTitle: "Leverage Qualified Leads",
          title: "Prospect from Target Groups",
          body: "Using an integrated approach, Occidental Management uses a proprietary process of researching and identifying qualified leads for our leasing team. This back-end work eliminates wasted time and puts the sales agents ahead of the curve when beginning the prospecting process for a property."
        },
        {
          icon: "sliders-h",
          preTitle: "On-Call Marketing Expertise ",
          title: "Customize the Pitch",
          body: "No department is a silo at Occidental Management. Interdepartmental teams work together across the company to ensure a high quality, customized experience for all qualified leads. This means content and collateral can be gathered quickly whether it's an updated brochure or branded email blast to new still images or aerial drone shots. Our marketing team provides timely, professional materials to suit a tenants needs."
        },
        {
          icon: "handshake",
          preTitle: "Engage Industry Experience",
          title: "Land the Deal",
          body: "Commercial real estate hinges on property location and professional relationships. Our veteran team knows the key players in and around the market, allowing them to customize a pitch that tailors to each decision makers' preferences. This unique and meaningful approach means a trustworthy partnership between brokers and tenants."
        },
        {
          icon: "file-signature",
          preTitle: "Full-Service Company ",
          title: "Seamless Transition",
          body: "As a full-service commercial real estate leasing company, Occidental Management is poised to efficiently hand off deals after expressed interest and qualification. The set up allows the sales and leasing team to move through the sales lifecycle – landing tenants in less time."
        },
      ],
      properties: [
        {
          "title": "Historic Union Station",
          "lat": 37.685389,
          "lng": -97.3295468,
          "address": "701 E. Douglas, Wichita KS 67202",
          "url": "https://www.occmgmt.com/properties/701-e-douglas-wichita-ks/",
          "status": "For Lease",
          "prop_id": "667",
          "image_url": "https://www.occmgmt.com/wp-content/uploads/fly-images/1439/ust-1-1360x780-c.jpg"
        },
        {
          "title": "The Ice House",
          "lat": 37.684491,
          "lng": -97.328486,
          "address": "165 S. Rock Island, Wichita KS 67202",
          "url": "https://www.occmgmt.com/properties/165-s-rock-island-wichita-ks/",
          "status": "For Lease",
          "prop_id": "687",
          "image_url": "https://www.occmgmt.com/wp-content/uploads/fly-images/688/The-Ice-House-in-Wichita-KS_Commercial-Property-For-Lease-by-Occidental-Management_1-1360x780-c.jpg"
        },
        {
          "title": "Tyler Pointe Shopping Center",
          "lat": 37.7080864,
          "lng": -97.4420229,
          "address": "8641 West 13th St. N Wichita KS 67212",
          "url": "https://www.occmgmt.com/properties/8641-west-13th-st-n-wichita-ks/",
          "status": "For Lease",
          "prop_id": "695",
          "image_url": "https://www.occmgmt.com/wp-content/uploads/fly-images/696/Tyler-Pointe-Shopping-Center-in-Wichita-KS_Commercial-Property-For-Lease-by-Occidental-Management_1-1360x780-c.jpg"
        },
        {
          "title": "Northrock 6 Offices",
          "lat": 37.7415764,
          "lng": -97.2423352,
          "address": "8111 E. 32nd St. N. Wichita KS 67226",
          "url": "https://www.occmgmt.com/properties/8111-e-32nd-st-n-wichita-ks/",
          "status": "For Lease",
          "prop_id": "709",
          "image_url": "https://www.occmgmt.com/wp-content/uploads/fly-images/710/Northrock-6-Offices_Commercial-Property-For-Lease-by-Occidental-Management_1-1360x780-c.jpg"
        },
        {
          "title": "Auburn Pointe Shopping Center",
          "lat": 37.6778035,
          "lng": -97.4952235,
          "address": "13303 W. Maple Wichita KS 67235",
          "url": "https://www.occmgmt.com/properties/13303-w-maple-wichita-ks/",
          "status": "For Lease",
          "prop_id": "750",
          "image_url": "https://www.occmgmt.com/wp-content/uploads/fly-images/751/Auburn-Pointe-Shopping-Center_For-Lease_Occidental-Management_1-1360x780-c.jpg"
        },
        {
          "title": "Wood 96",
          "lat": 37.74661,
          "lng": -97.26128130000001,
          "address": "6435 E 34th St N, Wichita, KS 67220, USA",
          "url": "https://www.occmgmt.com/properties/wood-96/",
          "status": "For Lease",
          "prop_id": "1082",
          "image_url": "https://www.occmgmt.com/wp-content/uploads/fly-images/1402/Occidental-Management_Wood-96-Spaces-for-Lease-in-Wichita-KS-1-1-1360x780-c.jpg"
        },
        {
          "title": "Royal I Shopping Center",
          "image": "https://www.occmgmt.com/wp-content/uploads/fly-images/1403/Property-Overview-for-Royal-I-Shopping-Center-for-Lease-by-Occidental-Management-1-1360x780-c.jpg",
          "lat": 37.69117689999999,
          "lng": -97.24363559999999,
          "address": "316 N Rock Rd, Wichita, KS 67206",
          "url": "https://www.occmgmt.com/properties/royal-i-shopping-center/",
          "status": "For Lease",
          "prop_id": "1109",
          "image_url": "https://www.occmgmt.com/wp-content/uploads/fly-images/1403/Property-Overview-for-Royal-I-Shopping-Center-for-Lease-by-Occidental-Management-1-1360x780-c.jpg"
        },
        {
          "title": "Northwest Centre",
          "lat": 37.7093292,
          "lng": -97.4399749,
          "address": " 8404-8606 W. 13th Street Wichita KS 67212",
          "url": "https://www.occmgmt.com/properties/northwest-centre/",
          "status": "For Lease",
          "prop_id": "1124",
          "image_url": "https://www.occmgmt.com/wp-content/uploads/fly-images/1405/Northwest-Centre_Properties-in-Shopping-Center-for-Lease-by-Occidental-Management-1-1360x780-c.jpg"
        },
        {
          "title": "The Offices at Cranbrook",
          "lat": 37.7220463,
          "lng": -97.2204574,
          "address": "10111 E. 21st St. N., Wichita, KS 67206",
          "url": "https://www.occmgmt.com/properties/10111-e-21st-st-n-wichita-ks/",
          "status": "For Lease",
          "prop_id": "691",
          "image_url": "https://www.occmgmt.com/wp-content/uploads/fly-images/692/The-Offices-at-Cranbrook-in-Wichita-KS_Commercial-Property-For-Lease-by-Occidental-Management_1-1360x780-c.jpg"
        },
        {
          "title": "Grand Hotel (Union Station)",
          "lat": 37.6857157,
          "lng": -97.3284801,
          "address": "801 E. Douglas, Wichita KS 67202",
          "url": "https://www.occmgmt.com/properties/grand-hotel-union-station/",
          "status": "For Lease",
          "prop_id": "683",
          "image_url": "https://www.occmgmt.com/wp-content/uploads/fly-images/1325/property-for-lease-at-Grand-Hotel-Union-Station_by-occidental-management-1360x780-c.jpg"
        },
        {
          "title": "Bel Aire, KS Parcels",
          "image_url": "https://www.occmgmt.com/wp-content/uploads/fly-images/2105/AerialMap_FullSize-1-1360x780-c.png",
          "lat": 37.78317804009309,
          "lng": -97.21071634240266,
          "address": "5500 N 111th St E, Bel Aire, KS 67226, USA",
          "url": "https://www.occmgmt.com/properties/bel-aire-ks-parcels/",
          "status": "For Sale",
          "prop_id": "2086"
        },
        {
          "title": "Central Plaza",
          "image_url": "https://www.occmgmt.com/wp-content/uploads/fly-images/2121/CP.Vacancy-2-1360x780-c.jpg",
          "lat": 37.6934664,
          "lng": -97.2750873,
          "address": "5231 E Central Ave, Wichita, KS, USA",
          "url": "https://www.occmgmt.com/properties/central-plaza/",
          "status": "For Lease",
          "prop_id": "2013"
        },
        {
          "title": "Travel Air Building",
          "image_url": "https://www.occmgmt.com/wp-content/uploads/fly-images/1071/Occidental-Management_Travel-Air-Building-for-Lease_Wichita-KS-1360x780-c.jpg",
          "lat": 37.6844342,
          "lng": -97.3455371,
          "address": "535 W Douglas Ave, Wichita, KS 67213",
          "url": "https://www.occmgmt.com/properties/travel-air-building/",
          "status": "For Lease",
          "prop_id": "1068"
        },
        {
          "title": "Sierra Pointe",
          "image_url": "https://www.occmgmt.com/wp-content/uploads/fly-images/1409/site-plan-sierra-pointe-new-development-by-occidental-management-1-1360x780-c.jpg",
          "lat": 37.64805800957721,
          "lng": -97.19544181750487,
          "address": "11933 E Pawnee St, Wichita, KS 67207, USA",
          "url": "https://www.occmgmt.com/properties/sierra-pointe/",
          "status": "For Lease",
          "prop_id": "1300"
        },
        {
          "title": "Newton Retail",
          "image_url": "https://www.occmgmt.com/wp-content/uploads/fly-images/1203/Occidental-Management_Newton-Retail-Spaces-for-Lease-1-1360x780-c.jpg",
          "lat": 38.0237269,
          "lng": -97.33882829999999,
          "address": "1701 S Kansas Ave, Newton, KS 67114",
          "url": "https://www.occmgmt.com/properties/newton-retail/",
          "status": "For Lease",
          "prop_id": "1202"
        },
        {
          "title": "Edgemoor Central Development",
          "image_url": "https://www.occmgmt.com/wp-content/uploads/fly-images/1282/Occidental-Management_Edgemoor-Central-Development_Property-for-lease-or-for-sale-2-1360x780-c.jpg",
          "lat": 37.6953093,
          "lng": -97.2708493,
          "address": "620 N Edgemoor St, Wichita, KS 67208",
          "url": "https://www.occmgmt.com/properties/edgemoor-central-development/",
          "status": "For Lease",
          "prop_id": "1211"
        }
      ]
    }
  }
}
</script>

<style lang="scss">
</style>
