<template>
  <div class="w-100 dark section section-funnel position-relative">
    <div class="stem"></div>
    <div
      v-for="(section, index) in sections"
      :key="index"
      class="timeline-section-container container-fluid"
    >
      <div class="position-relative">
        <div class="timeline-icon ps-5 ps-md-2 p-2">
          <font-awesome-icon :icon="['fas', section.icon]" class="icon" />
          <div class="d-md-none timeline-icon-dot position-absolute top-50 translate-middle"></div>
        </div>
        <div
          :class="`col-12 ps-5 col-md-6 ${index % 2 ? 'offset-md-6 ps-md-5' : 'pe-md-5 offset-md-0'}`"
          data-aos="fade"
        >
          <p class="pre-title">{{ section.preTitle }}</p>
          <h1>{{ section.title }}</h1>
          <p>{{ section.body }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Timeline',
  props: {
    sections: {
      type: Array,
      required: true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
