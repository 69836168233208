import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faFilter as fasFilter,
  faSlidersH as fasSlidersH,
  faHandshake as fasHandshake,
  faFileSignature as fasFileSignature,
} from '@fortawesome/pro-solid-svg-icons'

import {
  faExternalLink as farExternalLink,
  faDownload as farDownload,
  faBars as farBars,
  faTimes as farTimes
} from '@fortawesome/pro-regular-svg-icons'

import {
  faFileSignature as falFileSignature,
  faTimes as falTimes
} from '@fortawesome/pro-light-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  // Light
  falFileSignature,
  falTimes,
  // Regular
  farExternalLink,
  farDownload,
  farBars,
  farTimes,
  // Solid
  fasFilter,
  fasFileSignature,
  fasHandshake,
  fasSlidersH,
)

Vue.component('font-awesome-icon', FontAwesomeIcon)