<template>
  <div class="min-vh-100 w-100 d-flex section overflow-hidden">
    <GmapMap
      :center='center'
      :zoom='12'
      style='width:100%; height: 100vh;'
      :options="{
        mapTypeControl: false,
        rotateControl: false,
        fullscreenControl: false,
        mapId: '3947761cb2538b76'
      }"
    >
      <GmapMarker
        :key="index"
        v-for="(m, index) in properties"
        :position="{lat: m.lat, lng: m.lng}"
        @click="currentPlace=m"
        :icon="currentPlace != null && m.prop_id == currentPlace.prop_id ? markerIconActive : markerIcon"
      />
    </GmapMap>
    <div class="current-property-container d-flex">
      <div v-if="currentPlace == null" class="default-message align-self-center text-center w-100">
        Click A Pin to learn more
      </div>
      <div
        v-if="currentPlace != null"
        class="property-details w-100 h-100 d-flex"
        :style="`background-image: url('${currentPlace.image_url}')`"
      >
        <button
          type="button"
          class="property-close-button"
          @click="currentPlace=null"
        >
          <font-awesome-icon :icon="['fal', 'times']" class="icon" />
        </button>
        <div class="text-container d-flex p-2 align-items-end align-self-end w-100">
          <div class="flex-grow-1">
            <p class="pre-title mb-1">{{currentPlace.status}}</p>
            <p class="fw-bolder fs-5 mb-2">{{currentPlace.title}}</p>
            <p class="fs-6 mb-0">{{currentPlace.address}}</p>
          </div>
          <div>
            <a class="btn btn-outline-primary btn-round btn-sm" :href="currentPlace.url">
              <font-awesome-icon :icon="['far', 'external-link']" class="icon" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { range, random } from 'lodash';

export default {
  name: 'Map',

  props: {
    properties: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      center: { lat: 37.6872, lng: -97.3301 },
      currentPlace: null,
      markerIcon: {url: './img/map-marker-default.png'},
      markerIconActive: {url: './img/map-marker-active.png'},
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
