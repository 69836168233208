import Vue from 'vue'
import App from './App.vue'
import scrollSpy, { Easing } from 'vue2-scrollspy';
import * as VueGoogleMaps from 'vue2-google-maps'
import AOS from 'aos';
require('aos/dist/aos.css');

require('./scripts/fontawesome.js')

require('./assets/styles/main.scss');

AOS.init();
Vue.config.productionTip = false

Vue.use(scrollSpy, {
  easing: Easing.Cubic.InOut
});

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDMCgOl5P7KFCIW3VFy_C0SVZCtddRy1z8',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
  },
  installComponents: true
});

new Vue({
  render: h => h(App),
}).$mount('#app')
