<template>
  <div class="stat col">
    <font-awesome-icon :icon="['fal', icon]" class="icon mb-4" />
    <h1 class="mb-0 lh-1">{{ value }}</h1>
    <p class="pre-title">{{ label }}</p>
  </div>
</template>

<script>

export default {
  name: 'Stat',
  props: {
    value: String,
    label: String,
    icon: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
