<template>
  <div :class="`min-vh-100 w-100 dark d-flex section section-text-${textSide}`">
    <div class="container-fluid align-self-end align-self-sm-center position-relative py-sm-2 py-5" style="z-index: 100;">
      <div :class="`row ${textSide == 'right' ? 'justify-content-end' : ''}`">
        <div
          :class="`col-12 col-md-7 col-xl-6 ${textSide == 'right' ? 'ps-lg-5' : 'pe-lg-5'}`"
        >
          <div data-aos="fade">
            <p class="pre-title">{{ preTitle }}</p>
            <h1 class="bottom-border">{{ title }}</h1>
            <slot></slot>
          </div>
          <div v-if="stats" class="row mt-5">
            <stat
              v-for="(stat) in stats"
              :key="stat.label"
              :value="stat.value"
              :label="stat.label"
              :icon="stat.icon"
              data-aos="fade"
              :data-aos-delay="350"
            ></stat>
          </div>
        </div>
        <div data-aos="fade-in"><slot name="bottom-row"></slot></div>
      </div>
    </div>
    <div :class="`bg-image ${textSide == 'right' ? 'start-0' : 'end-0'}`">
      <img :src="require(`@/assets/${imageUrl}`)" alt="">
      <div class="bg-dark-gradient">&nbsp;</div>
    </div>
  </div>
</template>

<script>
import Stat from './Stat.vue'

export default {
  name: 'TextHalf',
  components: {
    Stat
  },
  props: {
    preTitle: String,
    title: String,
    imageUrl: String,
    stats: Array,
    textSide: {
      type: String,
      default: 'left'
    }
  },
  data () {
    return {
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
